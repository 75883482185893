<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row justify-space-between">
				<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
				<button
					class="btn-inline btn-primary radius-20"
					@click="toCartelRequest"
					:disabled="is_open_cartel"
				>카르텔 개설</button>
			</div>

			<div>
				<ul
					v-if="items.length > 0"
				>
					<li
						v-for="(item, index) in items_list"
						:key="'item_' + index"
						class="pt-30 flex-row justify-space-between "
					>
						<div>
							<div>{{ item.cartl_name }}</div>
							<div class="mt-10 color-gray size-px-12">{{ item.cartl_open_requestdate | transDate }}</div>
						</div>
						<div>
							<span class="label" :class="item.label">{{ item.cartl_open_request_state_code_name }}</span>
							<button
								class="ml-10"
								@click="onMore(item)"
								:disabled="!item.is_on_more"
							><v-icon>mdi-dots-vertical</v-icon></button>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="flex-column justify-center text-center none"
					style="margin-top: 15%;"
				>카르텔 개설 요청 내역이 없습니다.</div>
			</div>
		</div>

		<PopupSelect
			v-if="item_more.cartl_open_request_number"
			@cancel="item_more = {}"

			class="z-index-layer"
		>
			<template
				v-slot:title
			>{{  item_more.cartl_name }}</template>
			<template
				v-slot:list
			>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700003'"
					class="cursor-pointer ptb-10"
					@click="onRequestCancel"
				>요청 취소</li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700003'"
					class="cursor-pointer ptb-10"
					@click="toModify"
				>수정</li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700005'"
					class="ptb-10 text-center"
				>
					<div class="">
						<h5>MAFIA 카르텔 개설 불가판정을 받았습니다.</h5>
					</div>
					<div class="mt-30 top-line">
						<h6 class="mt-30">개설 불가 사유</h6>
						<div class="mt-10 size-px-12 color-gray">{{ item_more.open_request_reply }}</div>
					</div>
					<div class="mt-50">
						<button
							class="btn-inline btn-primary radius-20 mr-10"
							@click="toModify"
						>재신청</button>
						<button
							class="btn-inline btn-default radius-20"
							@click="item_more = {}"
						>확인</button>
					</div>
				</li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700006'"
					class="cursor-pointer ptb-10"
					@click="toCartel"
				>이동</li>
			</template>
		</PopupSelect>

		<PopupConfirm
			v-if="is_on_request_cancel"
			@click="postCancel"
			@cancel="is_on_request_cancel = false"
		>
			<template
				v-slot:title
			>카르텔 개설 요청 취소</template>
			<template
				v-slot:main-txt
			>해당 카르텔 개설 요청을 취소 하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>개설 요청을 취소 하시려면 확인을 눌러주세요.</template>
		</PopupConfirm>
	</div>
</template>

<script>
	import MypageSide from "@/view/Mypage/mypage-lnb";
	import PopupSelect from "@/view/Layout/PopupSelect";
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'CartelRequestList'
		, components: {PopupConfirm, PopupSelect, MypageSide}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'CartelRequestList'
					, title: '카르텔 개설 요청 목록'
					, type: 'mypage'
					, bg_contents: 'bg-gray01'
				}
				, items: []
				, item_last: { code: ''}
				, item_more: {}
				, items_request_status: [
					{ code: '', name: ''}
					, { code: '', name: ''}
				]
				, is_on_request_cancel: false
			}
		}
		, computed: {
			is_open_cartel: function(){
				let t = false
				if(this.item_last.code == 'Y'){
					t = true
				}
				return t
			}
			, items_list: function(){
				return this.items.filter((item) => {
					switch (item.cartl_open_request_state_code){
						case 'CA02700002':
							item.label = 'label-warning'
							break
						case 'CA02700003':
							item.label = 'label-progress'
							item.is_on_more = true
							break
						case 'CA02700004':
							item.label = 'label-progress'
							break
						case 'CA02700005':
							item.label = 'label-danger'
							item.is_on_more = true
							break
						case 'CA02700006':
							item.label = 'label-success'
							item.is_on_more = true
							break
					}
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_request_list
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.cartl_open_request_list
						if(this.items.length > 0){
							this.item_last = this.items[this.items.length - 1]
						}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCancel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_request_cancel
						, data: {
							member_number: this.user.member_number
							, cartl_open_request_number: this.item_more.cartl_open_request_number
						}
						, type: true
					})

					if(result.success){
						await this.getData()
						this.item_more = {}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_on_request_cancel = false
				}
			}
			, toCartelRequest: function(){
				this.$bus.$emit('to', { name: 'CartelRequest'})
			}
			, onMore: function(item){
				this.item_more = item
				this.getRequest()
			}
			, onRequestCancel: function(){
				this.is_on_request_cancel = true
			}
			, toModify: function(){
				this.$bus.$emit('to', { name: 'CartelRequestDetail', params: { idx: this.item_more.cartl_open_request_number}})
			}
			, toCartel: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.item_more.cartl_number}})
			}
			, getRequest: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_request
						, data: {
							member_number: this.user.member_number
							, cartl_open_request_number: this.item_more.cartl_open_request_number
						}
						, type: true
					})

					if(result.success){
						this.$set(this.item_more, 'open_request_reply', result.data.open_request_reply)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toList: function(){
				this.$emit('goBack')
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>